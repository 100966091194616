// src/services/googleDriveService.js
const SCOPES = [
    'https://www.googleapis.com/auth/drive.file',
    'https://www.googleapis.com/auth/drive.appdata'
  ];
  
  class GoogleDriveService {
    constructor() {
      this.clientId = import.meta.env.VITE_GOOGLE_DRIVE_CLIENT_ID;
      this.clientSecret = import.meta.env.VITE_GOOGLE_DRIVE_CLIENT_SECRET;
      this.redirectUri = import.meta.env.VITE_GOOGLE_DRIVE_REDIRECT_URI;
    }
  
    getAuthUrl() {
      const url = new URL('https://accounts.google.com/o/oauth2/v2/auth');
      url.searchParams.append('client_id', this.clientId);
      url.searchParams.append('redirect_uri', this.redirectUri);
      url.searchParams.append('response_type', 'code');
      url.searchParams.append('scope', SCOPES.join(' '));
      url.searchParams.append('access_type', 'offline');
      url.searchParams.append('prompt', 'consent');
      
      return url.toString();
    }
  
    async handleAuthCode(code) {
      try {
        const response = await fetch('https://oauth2.googleapis.com/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            code,
            client_id: this.clientId,
            client_secret: this.clientSecret,
            redirect_uri: this.redirectUri,
            grant_type: 'authorization_code',
          }),
        });
  
        if (!response.ok) {
          throw new Error('Failed to get access token');
        }
  
        const tokens = await response.json();
        localStorage.setItem('googleDriveTokens', JSON.stringify(tokens));
        return true;
      } catch (error) {
        console.error('Error handling auth code:', error);
        throw error;
      }
    }
  
    async refreshAccessToken(refreshToken) {
      try {
        const response = await fetch('https://oauth2.googleapis.com/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            refresh_token: refreshToken,
            client_id: this.clientId,
            client_secret: this.clientSecret,
            grant_type: 'refresh_token',
          }),
        });
  
        if (!response.ok) {
          throw new Error('Failed to refresh token');
        }
  
        const tokens = await response.json();
        const savedTokens = JSON.parse(localStorage.getItem('googleDriveTokens'));
        const updatedTokens = { ...savedTokens, ...tokens };
        localStorage.setItem('googleDriveTokens', JSON.stringify(updatedTokens));
        return updatedTokens;
      } catch (error) {
        console.error('Error refreshing token:', error);
        throw error;
      }
    }
  
    async getAccessToken() {
      const tokens = JSON.parse(localStorage.getItem('googleDriveTokens'));
      if (!tokens) return null;
  
      if (new Date().getTime() > tokens.expires_in * 1000) {
        return (await this.refreshAccessToken(tokens.refresh_token)).access_token;
      }
  
      return tokens.access_token;
    }
  
    async uploadFile(file, parentFolderId) {
      try {
        const accessToken = await this.getAccessToken();
        if (!accessToken) throw new Error('Not authenticated');
  
        // First, create the file metadata
        const metadata = {
          name: file.name,
          parents: parentFolderId ? [parentFolderId] : undefined
        };
  
        const form = new FormData();
        form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
        form.append('file', file);
  
        const response = await fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
          body: form
        });
  
        if (!response.ok) {
          throw new Error('Failed to upload file');
        }
  
        const result = await response.json();
        return {
          fileId: result.id,
          viewLink: `https://drive.google.com/file/d/${result.id}/view`
        };
      } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
      }
    }
  
    async createFolder(name, parentFolderId = null) {
      try {
        const accessToken = await this.getAccessToken();
        if (!accessToken) throw new Error('Not authenticated');
  
        const metadata = {
          name,
          mimeType: 'application/vnd.google-apps.folder',
          parents: parentFolderId ? [parentFolderId] : undefined
        };
  
        const response = await fetch('https://www.googleapis.com/drive/v3/files', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(metadata)
        });
  
        if (!response.ok) {
          throw new Error('Failed to create folder');
        }
  
        const result = await response.json();
        return result.id;
      } catch (error) {
        console.error('Error creating folder:', error);
        throw error;
      }
    }
  
    async deleteFile(fileId) {
      try {
        const accessToken = await this.getAccessToken();
        if (!accessToken) throw new Error('Not authenticated');
  
        const response = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
  
        if (!response.ok) {
          throw new Error('Failed to delete file');
        }
  
        return true;
      } catch (error) {
        console.error('Error deleting file:', error);
        throw error;
      }
    }
  
    async getOrCreateRootFolder(folderName) {
      try {
        const accessToken = await this.getAccessToken();
        if (!accessToken) throw new Error('Not authenticated');
  
        // Search for existing folder
        const searchResponse = await fetch(
          `https://www.googleapis.com/drive/v3/files?q=name='${folderName}' and mimeType='application/vnd.google-apps.folder' and trashed=false`,
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          }
        );
  
        const searchResult = await searchResponse.json();
        if (searchResult.files && searchResult.files.length > 0) {
          return searchResult.files[0].id;
        }
  
        // Create new folder if it doesn't exist
        return await this.createFolder(folderName);
      } catch (error) {
        console.error('Error getting/creating root folder:', error);
        throw error;
      }
    }
  }
  
  export const googleDriveService = new GoogleDriveService();