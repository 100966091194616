// src/components/TransactionList.jsx
import React from 'react';
import { Edit2, Trash2, ExternalLink, Receipt, Calendar, DollarSign, CreditCard, FileText } from 'lucide-react';
import { Button } from './ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { Card } from './ui/card';
import dayjs from 'dayjs';

const TransactionList = ({ 
  transactions, 
  loading, 
  onDelete, 
  onEdit 
}) => {
  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!transactions.length) {
    return (
      <div className="text-center py-12">
        <p className="text-muted-foreground">No transactions found</p>
      </div>
    );
  }

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  // Mobile card view component
  const MobileTransactionCard = ({ transaction }) => (
    <Card className="mb-4 last:mb-0">
      <div className="p-4 space-y-4">
        {/* Header: Date and Amount */}
        <div className="flex items-start justify-between">
          <div className="flex items-center space-x-2 text-sm">
            <Calendar className="h-4 w-4 text-muted-foreground" />
            <span>{dayjs(transaction.date).format('MMM D, YYYY')}</span>
          </div>
          <span className={`font-semibold ${
            transaction.type === 'expense' 
              ? 'text-red-500' 
              : 'text-green-500'
          }`}>
            {formatCurrency(transaction.amount)}
          </span>
        </div>

        {/* Type and Category */}
        <div className="flex items-center justify-between">
          <span className={`text-sm px-2 py-1 rounded-full ${
            transaction.type === 'expense'
              ? 'bg-red-50 text-red-600'
              : 'bg-green-50 text-green-600'
          }`}>
            {transaction.type.charAt(0).toUpperCase() + transaction.type.slice(1)}
          </span>
          <span className="text-sm text-muted-foreground">
            {transaction.category}
          </span>
        </div>

        {/* Description */}
        {transaction.description && (
          <div className="flex items-start space-x-2">
            <FileText className="h-4 w-4 text-muted-foreground mt-0.5" />
            <p className="text-sm flex-1">{transaction.description}</p>
          </div>
        )}

        {/* Payment Method */}
        <div className="flex items-center space-x-2">
          <CreditCard className="h-4 w-4 text-muted-foreground" />
          <span className="text-sm">{transaction.paymentMethod}</span>
        </div>

        {/* Receipt and Actions */}
        <div className="flex items-center justify-between pt-2 border-t">
          <div>
            {transaction.receipt ? (
              <a
                href={transaction.receipt.url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-1 text-sm text-blue-500 hover:text-blue-600"
              >
                <ExternalLink className="h-4 w-4" />
                <span>View Receipt</span>
              </a>
            ) : transaction.type === 'expense' ? (
              <span className="flex items-center space-x-1 text-sm text-muted-foreground">
                <Receipt className="h-4 w-4" />
                <span>No Receipt</span>
              </span>
            ) : null}
          </div>
          <div className="flex space-x-2">
            <Button
              variant="ghost"
              size="sm"
              onClick={() => onEdit(transaction)}
              className="h-8 px-2"
            >
              <Edit2 className="h-4 w-4" />
            </Button>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => onDelete(transaction.id)}
              className="h-8 px-2 text-red-500 hover:text-red-600"
            >
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );

  return (
    <>
      {/* Mobile View */}
      <div className="lg:hidden space-y-4 p-4">
        {transactions.map((transaction) => (
          <MobileTransactionCard 
            key={transaction.id} 
            transaction={transaction} 
          />
        ))}
      </div>

      {/* Desktop View */}
      <div className="hidden lg:block">
        <div className="rounded-lg border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Date</TableHead>
                <TableHead>Type</TableHead>
                <TableHead>Category</TableHead>
                <TableHead>Description</TableHead>
                <TableHead className="text-right">Amount</TableHead>
                <TableHead>Payment Method</TableHead>
                <TableHead>Receipt</TableHead>
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {transactions.map((transaction) => (
                <TableRow key={transaction.id}>
                  <TableCell>
                    {dayjs(transaction.date).format('MMM D, YYYY')}
                  </TableCell>
                  <TableCell>
                    <span className={`px-2 py-1 rounded-full text-sm ${
                      transaction.type === 'expense' 
                        ? 'bg-red-50 text-red-600' 
                        : 'bg-green-50 text-green-600'
                    }`}>
                      {transaction.type.charAt(0).toUpperCase() + transaction.type.slice(1)}
                    </span>
                  </TableCell>
                  <TableCell>{transaction.category}</TableCell>
                  <TableCell className="max-w-xs truncate">
                    {transaction.description}
                  </TableCell>
                  <TableCell className={`text-right font-medium ${
                    transaction.type === 'expense' 
                      ? 'text-red-500' 
                      : 'text-green-500'
                  }`}>
                    {formatCurrency(transaction.amount)}
                  </TableCell>
                  <TableCell>{transaction.paymentMethod}</TableCell>
                  <TableCell>
                    {transaction.receipt ? (
                      <a
                        href={transaction.receipt.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:text-blue-600"
                      >
                        <ExternalLink className="h-4 w-4" />
                      </a>
                    ) : transaction.type === 'expense' ? (
                      <Receipt className="h-4 w-4 text-muted-foreground" />
                    ) : null}
                  </TableCell>
                  <TableCell className="text-right space-x-2">
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => onEdit(transaction)}
                    >
                      <Edit2 className="h-4 w-4" />
                    </Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="text-red-500 hover:text-red-600"
                      onClick={() => onDelete(transaction.id)}
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default TransactionList;