// src/services/firestoreService.js
import { 
    collection, 
    doc, 
    setDoc, 
    getDoc, 
    getDocs, 
    addDoc, 
    updateDoc, 
    deleteDoc, 
    query, 
    where, 
    orderBy, 
    limit 
  } from '@firebase/firestore';
  import { db } from '../lib/firebase';
  
  export const firestoreService = {
    // Business Operations
    async createBusiness(userId, businessData) {
      try {
        const businessRef = collection(db, 'businesses');
        const newBusiness = {
          ...businessData,
          userId,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };
        const docRef = await addDoc(businessRef, newBusiness);
        return { id: docRef.id, ...newBusiness };
      } catch (error) {
        console.error('Error creating business:', error);
        throw error;
      }
    },
  
    async updateBusiness(businessId, updateData) {
      try {
        const businessRef = doc(db, 'businesses', businessId);
        await updateDoc(businessRef, {
          ...updateData,
          updatedAt: new Date().toISOString()
        });
        return true;
      } catch (error) {
        console.error('Error updating business:', error);
        throw error;
      }
    },
  
    // Transaction Operations
    async addTransaction(businessId, transactionData) {
      try {
        const transactionsRef = collection(db, `businesses/${businessId}/transactions`);
        const newTransaction = {
          ...transactionData,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };
        const docRef = await addDoc(transactionsRef, newTransaction);
        return { id: docRef.id, ...newTransaction };
      } catch (error) {
        console.error('Error adding transaction:', error);
        throw error;
      }
    },
  
    async updateTransaction(businessId, transactionId, updateData) {
      try {
        const transactionRef = doc(db, `businesses/${businessId}/transactions/${transactionId}`);
        await updateDoc(transactionRef, {
          ...updateData,
          updatedAt: new Date().toISOString()
        });
        return true;
      } catch (error) {
        console.error('Error updating transaction:', error);
        throw error;
      }
    },
  
    async getTransactions(businessId, filters = {}) {
      try {
        const transactionsRef = collection(db, `businesses/${businessId}/transactions`);
        let q = query(transactionsRef, orderBy('date', 'desc'));
  
        // Apply filters if they exist
        if (filters.startDate) {
          q = query(q, where('date', '>=', filters.startDate));
        }
        if (filters.endDate) {
          q = query(q, where('date', '<=', filters.endDate));
        }
        if (filters.type) {
          q = query(q, where('type', '==', filters.type));
        }
        if (filters.category) {
          q = query(q, where('category', '==', filters.category));
        }
  
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error('Error fetching transactions:', error);
        throw error;
      }
    },
  
    // User Settings Operations
    async initializeUserSettings(userId) {
      try {
        const settingsRef = doc(db, 'userSettings', userId);
        const defaultSettings = {
          expenseCategories: [
            'Advertising',
            'Office Supplies',
            'Software/Subscriptions',
            'Travel',
            'Meals',
            'Insurance',
            'Professional Services',
            'Equipment',
            'Other'
          ],
          paymentMethods: [
            { id: 'company-card', name: 'Company Card', type: 'credit-card' },
            { id: 'personal-card', name: 'Personal Card', type: 'credit-card' },
            { id: 'cash', name: 'Cash', type: 'cash' }
          ],
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };
        await setDoc(settingsRef, defaultSettings);
        return defaultSettings;
      } catch (error) {
        console.error('Error initializing user settings:', error);
        throw error;
      }
    },
  
    async getUserSettings(userId) {
      try {
        const settingsRef = doc(db, 'userSettings', userId);
        const docSnap = await getDoc(settingsRef);
        
        if (!docSnap.exists()) {
          // Initialize default settings if they don't exist
          return await this.initializeUserSettings(userId);
        }
        
        return { id: docSnap.id, ...docSnap.data() };
      } catch (error) {
        console.error('Error fetching user settings:', error);
        throw error;
      }
    }
  };