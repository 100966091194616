// src/components/FileUpload.jsx
import React, { useState } from 'react';
import { Upload, X, FileIcon, Loader2 } from 'lucide-react';
import { useGoogleDrive } from '../hooks/useGoogleDrive';
import { googleDriveService } from '../services/googleDriveService';
import { Button } from './ui/button';
import { cn } from '../lib/utils';

const FileUpload = ({ 
  onFileUploaded, 
  existingFileId = null,
  existingFileUrl = null,
  className 
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(
    existingFileId && existingFileUrl 
      ? { id: existingFileId, url: existingFileUrl }
      : null
  );
  const { isAuthenticated, rootFolderId, startAuth } = useGoogleDrive();

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!isAuthenticated) {
      startAuth();
      return;
    }

    setIsUploading(true);
    try {
      const { fileId, viewLink } = await googleDriveService.uploadFile(file, rootFolderId);
      const fileInfo = { id: fileId, url: viewLink, name: file.name };
      setUploadedFile(fileInfo);
      onFileUploaded?.(fileInfo);
    } catch (error) {
      console.error('Upload error:', error);
      // You might want to show a toast notification here
    } finally {
      setIsUploading(false);
    }
  };

  const handleRemove = async () => {
    if (!uploadedFile) return;

    try {
      await googleDriveService.deleteFile(uploadedFile.id);
      setUploadedFile(null);
      onFileUploaded?.(null);
    } catch (error) {
      console.error('Delete error:', error);
      // You might want to show a toast notification here
    }
  };

  return (
    <div className={cn("w-full", className)}>
      {!uploadedFile ? (
        <div 
          className={cn(
            "border-2 border-dashed rounded-lg p-4 text-center",
            isUploading ? "opacity-50 cursor-not-allowed" : "hover:border-primary/50 cursor-pointer"
          )}
        >
          <input
            type="file"
            onChange={handleFileSelect}
            className="hidden"
            id="file-upload"
            accept="image/*,.pdf"
            disabled={isUploading}
          />
          <label
            htmlFor="file-upload"
            className={cn(
              "cursor-pointer block",
              isUploading && "cursor-not-allowed"
            )}
          >
            {isUploading ? (
              <Loader2 className="mx-auto h-8 w-8 animate-spin text-muted-foreground" />
            ) : (
              <Upload className="mx-auto h-8 w-8 text-muted-foreground" />
            )}
            <p className="mt-2 text-sm text-muted-foreground">
              {isUploading 
                ? 'Uploading...' 
                : !isAuthenticated
                ? 'Click to connect Google Drive'
                : 'Drop receipt here or click to browse'
              }
            </p>
          </label>
        </div>
      ) : (
        <div className="flex items-center justify-between p-2 border rounded-lg">
          <div className="flex items-center space-x-2">
            <FileIcon className="h-5 w-5 text-blue-500" />
            <a
              href={uploadedFile.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-blue-500 hover:underline max-w-[200px] truncate"
            >
              View Receipt
            </a>
          </div>
          <Button
            variant="ghost"
            size="sm"
            onClick={handleRemove}
            className="text-destructive hover:text-destructive/90"
          >
            <X className="h-4 w-4" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default FileUpload;