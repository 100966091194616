// src/services/transactionService.js
import { 
    collection, 
    addDoc, 
    updateDoc,
    deleteDoc,
    doc, 
    query, 
    where, 
    orderBy,
    getDocs,
    getDoc
  } from '@firebase/firestore';
  import { db } from '../lib/firebase';
  import dayjs from 'dayjs';
  
  export const transactionService = {
    async createTransaction(data, userId) {
      try {
        const transactionRef = collection(db, 'transactions');
        const newTransaction = {
          ...data,
          userId, // Add the userId
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };
        const docRef = await addDoc(transactionRef, newTransaction);
        return { id: docRef.id, ...newTransaction };
      } catch (error) {
        console.error('Error creating transaction:', error);
        throw error;
      }
    },
  
    async updateTransaction(transactionId, data, userId) {
      try {
        const transactionRef = doc(db, 'transactions', transactionId);
        const updateData = {
          ...data,
          userId, // Add the userId
          updatedAt: new Date().toISOString()
        };
        await updateDoc(transactionRef, updateData);
        return { id: transactionId, ...updateData };
      } catch (error) {
        console.error('Error updating transaction:', error);
        throw error;
      }
    },
  
    async deleteTransaction(transactionId) {
      try {
        const transactionRef = doc(db, 'transactions', transactionId);
        await deleteDoc(transactionRef);
        return true;
      } catch (error) {
        console.error('Error deleting transaction:', error);
        throw error;
      }
    },
  
    async getTransactions(businessId, userId, filters = {}) {
        try {
          const transactionsRef = collection(db, 'transactions');
          let constraints = [
            where('businessId', '==', businessId),
            where('userId', '==', userId)
          ];
    
          // Add type filter
          if (filters.type) {
            constraints.push(where('type', '==', filters.type));
          }
    
          // Add category filter
          if (filters.category) {
            constraints.push(where('category', '==', filters.category));
          }
    
          // Add payment method filter
          if (filters.paymentMethod) {
            constraints.push(where('paymentMethod', '==', filters.paymentMethod));
          }
    
          // Create the query
          let q = query(transactionsRef, ...constraints);
          const querySnapshot = await getDocs(q);
          let transactions = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
    
          // Apply date filters in memory
          if (filters.startDate) {
            transactions = transactions.filter(t => 
              t.date >= filters.startDate
            );
          }
          if (filters.endDate) {
            transactions = transactions.filter(t => 
              t.date <= filters.endDate
            );
          }
    
          // Apply amount filters in memory
          if (filters.minAmount) {
            transactions = transactions.filter(t => 
              t.amount >= parseFloat(filters.minAmount)
            );
          }
          if (filters.maxAmount) {
            transactions = transactions.filter(t => 
              t.amount <= parseFloat(filters.maxAmount)
            );
          }
    
          // Sort by date
          return transactions.sort((a, b) => 
            new Date(b.date).getTime() - new Date(a.date).getTime()
          );
        } catch (error) {
          console.error('Error fetching transactions:', error);
          throw error;
        }
    },
  
    async getTransaction(transactionId) {
      try {
        const transactionRef = doc(db, 'transactions', transactionId);
        const docSnap = await getDoc(transactionRef);
        
        if (!docSnap.exists()) {
          throw new Error('Transaction not found');
        }
  
        return {
          id: docSnap.id,
          ...docSnap.data()
        };
      } catch (error) {
        console.error('Error fetching transaction:', error);
        throw error;
      }
    },
  
    async getBusinessStats(businessId, userId, period = 'month') {
      try {
        const startDate = dayjs().startOf(period).format('YYYY-MM-DD');
        const endDate = dayjs().endOf(period).format('YYYY-MM-DD');
  
        const transactions = await this.getTransactions(businessId, userId);
  
        const stats = {
          totalExpenses: 0,
          totalIncome: 0,
          netProfit: 0,
          pendingReceipts: 0,
          expensesByCategory: {},
          incomeByCategory: {}
        };
  
        transactions.forEach(transaction => {
          if (transaction.type === 'expense') {
            stats.totalExpenses += transaction.amount;
            stats.expensesByCategory[transaction.category] = 
              (stats.expensesByCategory[transaction.category] || 0) + transaction.amount;
            if (!transaction.receipt) {
              stats.pendingReceipts++;
            }
          } else {
            stats.totalIncome += transaction.amount;
            stats.incomeByCategory[transaction.category] = 
              (stats.incomeByCategory[transaction.category] || 0) + transaction.amount;
          }
        });
  
        stats.netProfit = stats.totalIncome - stats.totalExpenses;
  
        return stats;
      } catch (error) {
        console.error('Error calculating business stats:', error);
        throw error;
      }
    }
  };