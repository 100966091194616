// src/components/TransactionForm.jsx
import React, { useState } from 'react';
import { useBusinesses } from '../hooks/useBusinesses';
import { Button } from './ui/button';
import { 
  Card, 
  CardHeader, 
  CardTitle, 
  CardDescription, 
  CardContent,
  CardFooter 
} from './ui/card';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select';
import FileUpload from './FileUpload';
import { Calendar } from 'lucide-react';
import dayjs from 'dayjs';

const expenseCategories = [
  'Advertising',
  'Office Supplies',
  'Software/Subscriptions',
  'Travel',
  'Meals',
  'Insurance',
  'Professional Services',
  'Equipment',
  'Rent',
  'Utilities',
  'Other'
];

const incomeCategories = [
  'Services',
  'Products',
  'Consulting',
  'Royalties',
  'Other Income'
];

const TransactionForm = ({ onSubmit, onCancel, initialData = null }) => {
  const { selectedBusiness } = useBusinesses();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    type: initialData?.type || 'expense',
    date: initialData?.date || dayjs().format('YYYY-MM-DD'),
    amount: initialData?.amount || '',
    category: initialData?.category || '',
    description: initialData?.description || '',
    paymentMethod: initialData?.paymentMethod || '',
    receipt: initialData?.receipt || null
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await onSubmit({
        ...formData,
        amount: parseFloat(formData.amount),
        businessId: selectedBusiness.id,
      });
    } catch (error) {
      console.error('Error submitting transaction:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileUploaded = (fileInfo) => {
    setFormData(prev => ({
      ...prev,
      receipt: fileInfo
    }));
  };

  return (
    <Card className="w-full max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle>
          {initialData ? 'Edit Transaction' : 'New Transaction'}
        </CardTitle>
        <CardDescription>
          {formData.type === 'expense' 
            ? 'Record a new expense' 
            : 'Record new income'}
        </CardDescription>
      </CardHeader>
      <form onSubmit={handleSubmit}>
        <CardContent className="space-y-4">
          {/* Transaction Type */}
          <div className="space-y-2">
            <label className="text-sm font-medium">
              Transaction Type
            </label>
            <Select
              value={formData.type}
              onValueChange={(value) => 
                setFormData(prev => ({ ...prev, type: value }))
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Select transaction type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="expense">Expense</SelectItem>
                <SelectItem value="income">Income</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {/* Date */}
          <div className="space-y-2">
            <label className="text-sm font-medium">
              Date
            </label>
            <div className="relative">
              <Calendar className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
              <input
                type="date"
                value={formData.date}
                onChange={(e) => 
                  setFormData(prev => ({ ...prev, date: e.target.value }))
                }
                className="w-full pl-8 pr-2 py-2 border rounded-md"
                required
              />
            </div>
          </div>

          {/* Amount */}
          <div className="space-y-2">
            <label className="text-sm font-medium">
              Amount
            </label>
            <div className="relative">
              <span className="absolute left-2 top-2">$</span>
              <input
                type="number"
                step="0.01"
                min="0"
                value={formData.amount}
                onChange={(e) => 
                  setFormData(prev => ({ ...prev, amount: e.target.value }))
                }
                className="w-full pl-6 pr-2 py-2 border rounded-md"
                placeholder="0.00"
                required
              />
            </div>
          </div>

          {/* Category */}
          <div className="space-y-2">
            <label className="text-sm font-medium">
              Category
            </label>
            <Select
              value={formData.category}
              onValueChange={(value) => 
                setFormData(prev => ({ ...prev, category: value }))
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Select category" />
              </SelectTrigger>
              <SelectContent>
                {(formData.type === 'expense' ? expenseCategories : incomeCategories)
                  .map(category => (
                    <SelectItem key={category} value={category}>
                      {category}
                    </SelectItem>
                  ))
                }
              </SelectContent>
            </Select>
          </div>

          {/* Payment Method */}
          <div className="space-y-2">
            <label className="text-sm font-medium">
              Payment Method
            </label>
            <Select
              value={formData.paymentMethod}
              onValueChange={(value) => 
                setFormData(prev => ({ ...prev, paymentMethod: value }))
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Select payment method" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="company-card">Company Card</SelectItem>
                <SelectItem value="personal-card">Personal Card</SelectItem>
                <SelectItem value="bank-transfer">Bank Transfer</SelectItem>
                <SelectItem value="cash">Cash</SelectItem>
                <SelectItem value="other">Other</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {/* Description */}
          <div className="space-y-2">
            <label className="text-sm font-medium">
              Description
            </label>
            <textarea
              value={formData.description}
              onChange={(e) => 
                setFormData(prev => ({ ...prev, description: e.target.value }))
              }
              className="w-full p-2 border rounded-md"
              rows="2"
              placeholder="Enter transaction details..."
            />
          </div>

          {/* Receipt Upload */}
          {formData.type === 'expense' && (
            <div className="space-y-2">
              <label className="text-sm font-medium">
                Receipt
              </label>
              <FileUpload
                onFileUploaded={handleFileUploaded}
                existingFileId={formData.receipt?.id}
                existingFileUrl={formData.receipt?.url}
              />
            </div>
          )}
        </CardContent>

        <CardFooter className="flex justify-end space-x-2">
          <Button
            type="button"
            variant="outline"
            onClick={onCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting 
              ? 'Saving...' 
              : initialData 
                ? 'Update Transaction' 
                : 'Add Transaction'
            }
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
};

export default TransactionForm;