// src/pages/Dashboard.jsx
import React from 'react';
import DashboardLayout from '../components/Layout/DashboardLayout';
import BusinessSelector from '../components/BusinessSelector';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { DollarSign, TrendingUp, TrendingDown, Activity } from 'lucide-react';
import { useBusinesses } from '../hooks/useBusinesses';

const Dashboard = () => {
  const { selectedBusiness } = useBusinesses();
  
  // This will be replaced with real data from Firebase later
  const summaryData = {
    totalExpenses: 12500.75,
    totalIncome: 25000.00,
    netProfit: 12499.25,
    pendingReceipts: 5
  };

  return (
    <DashboardLayout>
      <div className="space-y-4 lg:space-y-6">
        {/* Business Selector */}
        <BusinessSelector />

        {selectedBusiness && (
          <>
            {/* Page Header */}
            <div className="space-y-1">
              <h1 className="text-2xl lg:text-3xl font-bold">{selectedBusiness.name}</h1>
              <p className="text-sm lg:text-base text-muted-foreground">Overview of your business finances</p>
            </div>

            {/* Summary Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-4">
              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Total Expenses
                  </CardTitle>
                  <DollarSign className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-xl lg:text-2xl font-bold">
                    ${summaryData.totalExpenses.toLocaleString()}
                  </div>
                  <p className="text-xs text-muted-foreground">
                    Current month expenses
                  </p>
                </CardContent>
              </Card>

              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Total Income
                  </CardTitle>
                  <TrendingUp className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-xl lg:text-2xl font-bold">
                    ${summaryData.totalIncome.toLocaleString()}
                  </div>
                  <p className="text-xs text-muted-foreground">
                    Current month income
                  </p>
                </CardContent>
              </Card>

              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Net Profit
                  </CardTitle>
                  <TrendingDown className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-xl lg:text-2xl font-bold">
                    ${summaryData.netProfit.toLocaleString()}
                  </div>
                  <p className="text-xs text-muted-foreground">
                    Current month profit
                  </p>
                </CardContent>
              </Card>

              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Pending Receipts
                  </CardTitle>
                  <Activity className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-xl lg:text-2xl font-bold">
                    {summaryData.pendingReceipts}
                  </div>
                  <p className="text-xs text-muted-foreground">
                    Receipts to be uploaded
                  </p>
                </CardContent>
              </Card>
            </div>

            {/* Charts and Recent Transactions */}
            <div className="grid grid-cols-1 lg:grid-cols-7 gap-4">
              <Card className="lg:col-span-4">
                <CardHeader>
                  <CardTitle className="text-lg lg:text-xl">Recent Transactions</CardTitle>
                </CardHeader>
                <CardContent className="min-h-[300px]">
                  <p className="text-muted-foreground">Transaction list coming soon...</p>
                </CardContent>
              </Card>

              <Card className="lg:col-span-3">
                <CardHeader>
                  <CardTitle className="text-lg lg:text-xl">Expense Breakdown</CardTitle>
                </CardHeader>
                <CardContent className="min-h-[300px]">
                  <p className="text-muted-foreground">Expense chart coming soon...</p>
                </CardContent>
              </Card>
            </div>
          </>
        )}
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;