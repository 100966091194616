// src/context/BusinessContext.jsx
import React, { createContext, useState, useEffect } from 'react';
import { collection, query, where, getDocs, onSnapshot } from '@firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../hooks/useAuth';
import { Toast, ToastTitle, ToastDescription } from '../components/ui/toast';

export const BusinessContext = createContext();

export const BusinessProvider = ({ children }) => {
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();

  // Fetch user's businesses from Firestore
  useEffect(() => {
    if (!currentUser) {
      setBusinesses([]);
      setSelectedBusiness(null);
      setLoading(false);
      return;
    }

    let unsubscribe;

    const loadBusinesses = async () => {
      try {
        const businessesRef = collection(db, 'businesses');
        const q = query(businessesRef, where('userId', '==', currentUser.uid));
        
        // First, get initial data
        const querySnapshot = await getDocs(q);
        const businessesData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setBusinesses(businessesData);
        
        if (businessesData.length > 0 && !selectedBusiness) {
          setSelectedBusiness(businessesData[0]);
        }

        // Then set up real-time listener
        try {
          unsubscribe = onSnapshot(q, (snapshot) => {
            const updatedBusinesses = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            setBusinesses(updatedBusinesses);
          }, (error) => {
            // Handle permission-denied errors silently
            if (error.code !== 'permission-denied') {
              console.error('Snapshot error:', error);
              setError('Error loading businesses. Please refresh the page.');
            }
          });
        } catch (error) {
          // If real-time updates fail, we'll still have the initial data
          console.warn('Real-time updates unavailable:', error);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching businesses:', error);
        setError('Error loading businesses. Please refresh the page.');
        setLoading(false);
      }
    };

    loadBusinesses();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentUser]);

  const selectBusiness = (businessId) => {
    const business = businesses.find(b => b.id === businessId);
    setSelectedBusiness(business);
  };

  const value = {
    businesses,
    selectedBusiness,
    selectBusiness,
    loading,
    error
  };

  return (
    <BusinessContext.Provider value={value}>
      {children}
      {error && (
        <Toast>
          <ToastTitle>Error</ToastTitle>
          <ToastDescription>{error}</ToastDescription>
        </Toast>
      )}
    </BusinessContext.Provider>
  );
};