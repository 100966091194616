// src/components/TransactionFilters.jsx
import React from 'react';
import { Calendar, X } from 'lucide-react';
import { Button } from './ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from './ui/dialog';

const expenseCategories = [
  'Advertising',
  'Office Supplies',
  'Software/Subscriptions',
  'Travel',
  'Meals',
  'Insurance',
  'Professional Services',
  'Equipment',
  'Rent',
  'Utilities',
  'Other'
];

const incomeCategories = [
  'Services',
  'Products',
  'Consulting',
  'Royalties',
  'Other Income'
];

const TransactionFilters = ({ 
  isOpen, 
  onClose, 
  filters, 
  onApplyFilters,
  onResetFilters 
}) => {
  const [localFilters, setLocalFilters] = React.useState(filters);

  React.useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  const handleApply = () => {
    onApplyFilters(localFilters);
    onClose();
  };

  const handleReset = () => {
    const resetFilters = {
      startDate: '',
      endDate: '',
      type: '',
      category: '',
      paymentMethod: '',
      minAmount: '',
      maxAmount: ''
    };
    setLocalFilters(resetFilters);
    onResetFilters();
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Filter Transactions</DialogTitle>
          <DialogDescription>
            Set filters to narrow down your transactions
          </DialogDescription>
        </DialogHeader>

        <div className="grid gap-4 py-4">
          {/* Date Range */}
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label className="text-sm font-medium">Start Date</label>
              <div className="relative">
                <Calendar className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                <input
                  type="date"
                  value={localFilters.startDate}
                  onChange={(e) => setLocalFilters(prev => ({
                    ...prev,
                    startDate: e.target.value
                  }))}
                  className="w-full pl-8 pr-2 py-2 border rounded-md"
                />
              </div>
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium">End Date</label>
              <div className="relative">
                <Calendar className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                <input
                  type="date"
                  value={localFilters.endDate}
                  onChange={(e) => setLocalFilters(prev => ({
                    ...prev,
                    endDate: e.target.value
                  }))}
                  className="w-full pl-8 pr-2 py-2 border rounded-md"
                />
              </div>
            </div>
          </div>

          {/* Transaction Type */}
          <div className="space-y-2">
            <label className="text-sm font-medium">Transaction Type</label>
            <Select
              value={localFilters.type}
              onValueChange={(value) => setLocalFilters(prev => ({
                ...prev,
                type: value
              }))}
            >
              <SelectTrigger>
                <SelectValue placeholder="All Types" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="">All Types</SelectItem>
                <SelectItem value="expense">Expense</SelectItem>
                <SelectItem value="income">Income</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {/* Category */}
          <div className="space-y-2">
            <label className="text-sm font-medium">Category</label>
            <Select
              value={localFilters.category}
              onValueChange={(value) => setLocalFilters(prev => ({
                ...prev,
                category: value
              }))}
            >
              <SelectTrigger>
                <SelectValue placeholder="All Categories" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="">All Categories</SelectItem>
                {[...expenseCategories, ...incomeCategories].map(category => (
                  <SelectItem key={category} value={category}>
                    {category}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {/* Amount Range */}
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label className="text-sm font-medium">Min Amount</label>
              <div className="relative">
                <span className="absolute left-2 top-2">$</span>
                <input
                  type="number"
                  value={localFilters.minAmount}
                  onChange={(e) => setLocalFilters(prev => ({
                    ...prev,
                    minAmount: e.target.value
                  }))}
                  className="w-full pl-6 pr-2 py-2 border rounded-md"
                  placeholder="0.00"
                  min="0"
                  step="0.01"
                />
              </div>
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium">Max Amount</label>
              <div className="relative">
                <span className="absolute left-2 top-2">$</span>
                <input
                  type="number"
                  value={localFilters.maxAmount}
                  onChange={(e) => setLocalFilters(prev => ({
                    ...prev,
                    maxAmount: e.target.value
                  }))}
                  className="w-full pl-6 pr-2 py-2 border rounded-md"
                  placeholder="0.00"
                  min="0"
                  step="0.01"
                />
              </div>
            </div>
          </div>

          {/* Payment Method */}
          <div className="space-y-2">
            <label className="text-sm font-medium">Payment Method</label>
            <Select
              value={localFilters.paymentMethod}
              onValueChange={(value) => setLocalFilters(prev => ({
                ...prev,
                paymentMethod: value
              }))}
            >
              <SelectTrigger>
                <SelectValue placeholder="All Payment Methods" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="">All Payment Methods</SelectItem>
                <SelectItem value="company-card">Company Card</SelectItem>
                <SelectItem value="personal-card">Personal Card</SelectItem>
                <SelectItem value="bank-transfer">Bank Transfer</SelectItem>
                <SelectItem value="cash">Cash</SelectItem>
                <SelectItem value="other">Other</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        <DialogFooter className="flex justify-between">
          <Button
            variant="outline"
            onClick={handleReset}
          >
            Reset
          </Button>
          <div className="space-x-2">
            <Button
              variant="outline"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              onClick={handleApply}
            >
              Apply Filters
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default TransactionFilters;