// src/services/businessService.js
import { 
    collection, 
    addDoc, 
    query, 
    where, 
    getDocs, 
    deleteDoc,
    doc 
  } from '@firebase/firestore';
  import { db } from '../lib/firebase';
  
  export const businessService = {
    async createBusiness(userId, businessData) {
      try {
        const businessRef = collection(db, 'businesses');
        const newBusiness = {
          ...businessData,
          userId,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };
        const docRef = await addDoc(businessRef, newBusiness);
        return { id: docRef.id, ...newBusiness };
      } catch (error) {
        console.error('Error creating business:', error);
        throw error;
      }
    },
  
    async deleteBusiness(businessId) {
      try {
        const businessRef = doc(db, 'businesses', businessId);
        await deleteDoc(businessRef);
        return true;
      } catch (error) {
        console.error('Error deleting business:', error);
        throw error;
      }
    },
  
    async initializeDefaultBusinesses(userId) {
      try {
        // Check if user already has businesses
        const businessesRef = collection(db, 'businesses');
        const q = query(businessesRef, where('userId', '==', userId));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          console.log('User already has businesses initialized');
          return;
        }
  
        // Create default businesses
        const defaultBusinesses = [
          {
            name: 'McIntosh Media LLC',
            type: 'Single Member LLC',
            ownership: '100%',
            userId,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
          },
          {
            name: 'Reef Insights LLC',
            type: 'Partnership LLC',
            ownership: '50%',
            partners: [
              { name: 'Owner', share: 50 },
              { name: 'Partner', share: 50 }
            ],
            userId,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
          }
        ];
  
        const createdBusinesses = await Promise.all(
          defaultBusinesses.map(business => 
            addDoc(collection(db, 'businesses'), business)
          )
        );
  
        return createdBusinesses;
      } catch (error) {
        console.error('Error initializing default businesses:', error);
        throw error;
      }
    },
  
    async getUserBusinesses(userId) {
      try {
        const businessesRef = collection(db, 'businesses');
        const q = query(businessesRef, where('userId', '==', userId));
        const querySnapshot = await getDocs(q);
        
        return querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error('Error fetching user businesses:', error);
        throw error;
      }
    },
  
    async getBusinessStats(businessId) {
      try {
        // This will be implemented when we add transaction tracking
        // For now, return mock data
        return {
          totalExpenses: 0,
          totalIncome: 0,
          netProfit: 0,
          pendingReceipts: 0
        };
      } catch (error) {
        console.error('Error fetching business stats:', error);
        throw error;
      }
    }
  };