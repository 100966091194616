// src/context/GoogleDriveContext.jsx
import React, { createContext, useState, useEffect } from 'react';
import { googleDriveService } from '../services/googleDriveService';
import { useAuth } from '../hooks/useAuth';
import { Toast } from '../components/ui/toast';

export const GoogleDriveContext = createContext();

export const GoogleDriveProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [rootFolderId, setRootFolderId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    const checkAuth = async () => {
      if (!currentUser) {
        setIsAuthenticated(false);
        setRootFolderId(null);
        setLoading(false);
        return;
      }

      try {
        const accessToken = await googleDriveService.getAccessToken();
        if (accessToken) {
          setIsAuthenticated(true);
          const folderId = await googleDriveService.getOrCreateRootFolder(
            `business-expense-tracker-${currentUser.uid}`
          );
          setRootFolderId(folderId);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Error checking auth:', error);
        setError('Failed to authenticate with Google Drive');
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, [currentUser]);

  const startAuth = () => {
    try {
      const authUrl = googleDriveService.getAuthUrl();
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error starting auth:', error);
      setError('Failed to start authentication process');
    }
  };

  const clearError = () => setError(null);

  const value = {
    isAuthenticated,
    rootFolderId,
    loading,
    error,
    startAuth,
    clearError
  };

  return (
    <GoogleDriveContext.Provider value={value}>
      {children}
      {error && (
        <Toast onOpenChange={() => clearError()}>
          <div className="bg-destructive text-destructive-foreground px-4 py-2 rounded">
            {error}
          </div>
        </Toast>
      )}
    </GoogleDriveContext.Provider>
  );
};