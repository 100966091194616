// src/components/BusinessManagement.jsx
import React, { useState } from 'react';
import { Plus, Trash2 } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { useBusinesses } from '../hooks/useBusinesses';
import { businessService } from '../services/businessService';
import { Button } from './ui/button';
import { 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle, 
  DialogDescription,
  DialogFooter,
} from './ui/dialog';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from './ui/card';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/alert-dialog";

const BusinessManagement = ({ onBusinessAdded }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [businessToDelete, setBusinessToDelete] = useState(null);
  const [businessName, setBusinessName] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [ownership, setOwnership] = useState('100');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { currentUser } = useAuth();
  const { businesses } = useBusinesses();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const businessData = {
        name: businessName,
        type: businessType,
        ownership: `${ownership}%`,
        userId: currentUser.uid,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      // Add partners array if it's a Partnership LLC
      if (businessType === 'Partnership LLC') {
        businessData.partners = [
          { name: 'Owner', share: parseInt(ownership) },
          { name: 'Partner', share: 100 - parseInt(ownership) }
        ];
      }

      await businessService.createBusiness(currentUser.uid, businessData);
      setIsDialogOpen(false);
      setBusinessName('');
      setBusinessType('');
      setOwnership('100');
      
      if (onBusinessAdded) {
        onBusinessAdded();
      }
    } catch (error) {
      console.error('Error adding business:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (business) => {
    setBusinessToDelete(business);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await businessService.deleteBusiness(businessToDelete.id);
      setDeleteDialogOpen(false);
      setBusinessToDelete(null);
      if (onBusinessAdded) {
        onBusinessAdded();
      }
    } catch (error) {
      console.error('Error deleting business:', error);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Business Management</CardTitle>
        <CardDescription>Add and manage your businesses</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {/* List existing businesses */}
          <div className="space-y-4">
            {businesses.map((business) => (
              <div 
                key={business.id}
                className="flex items-center justify-between p-4 border rounded-lg"
              >
                <div>
                  <h3 className="font-medium">{business.name}</h3>
                  <p className="text-sm text-muted-foreground">
                    {business.type} - {business.ownership} ownership
                  </p>
                </div>
                <Button
                  variant="destructive"
                  size="icon"
                  onClick={() => handleDelete(business)}
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </div>
            ))}
          </div>

          {/* Add Business Button */}
          <Button 
            onClick={() => setIsDialogOpen(true)}
            className="w-full md:w-auto"
          >
            <Plus className="w-4 h-4 mr-2" />
            Add New Business
          </Button>
        </div>

        {/* Add Business Dialog */}
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add New Business</DialogTitle>
              <DialogDescription>
                Enter your business details below
              </DialogDescription>
            </DialogHeader>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-2">
                <label className="text-sm font-medium">
                  Business Name
                </label>
                <input
                  type="text"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  className="w-full p-2 border rounded-md"
                  required
                />
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium">
                  Business Type
                </label>
                <Select
                  value={businessType}
                  onValueChange={setBusinessType}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select business type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Single Member LLC">Single Member LLC</SelectItem>
                    <SelectItem value="Partnership LLC">Partnership LLC</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              {businessType === 'Partnership LLC' && (
                <div className="space-y-2">
                  <label className="text-sm font-medium">
                    Your Ownership Percentage
                  </label>
                  <div className="flex items-center space-x-2">
                    <input
                      type="number"
                      min="1"
                      max="99"
                      value={ownership}
                      onChange={(e) => setOwnership(e.target.value)}
                      className="w-full p-2 border rounded-md"
                      required
                    />
                    <span>%</span>
                  </div>
                </div>
              )}

              <DialogFooter>
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => setIsDialogOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting || !businessName || !businessType}
                >
                  {isSubmitting ? 'Adding...' : 'Add Business'}
                </Button>
              </DialogFooter>
            </form>
          </DialogContent>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Delete Business</AlertDialogTitle>
              <AlertDialogDescription>
                Are you sure you want to delete {businessToDelete?.name}? This action cannot be undone.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction
                onClick={confirmDelete}
                className="bg-red-500 hover:bg-red-600"
              >
                Delete
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </CardContent>
    </Card>
  );
};

export default BusinessManagement;