// src/pages/OAuthCallback.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { googleDriveService } from '../services/googleDriveService';
import { XCircle, AlertCircle, CheckCircle, Loader2 } from 'lucide-react';
import { Card, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';

const OAuthCallback = () => {
  const [status, setStatus] = useState('processing'); // 'processing', 'success', 'error'
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const error = urlParams.get('error');
        
        if (error) {
          throw new Error(getErrorMessage(error));
        }

        if (!code) {
          throw new Error('No authorization code received');
        }

        await googleDriveService.handleAuthCode(code);
        setStatus('success');
        
        // Navigate after a brief delay to show success state
        setTimeout(() => {
          navigate('/settings', { 
            state: { 
              message: 'Successfully connected to Google Drive' 
            }
          });
        }, 1500);

      } catch (error) {
        console.error('Auth callback error:', error);
        setError(error.message);
        setStatus('error');
        
        // Navigate back to settings after showing error
        setTimeout(() => {
          navigate('/settings', { 
            state: { 
              error: 'Failed to connect to Google Drive' 
            }
          });
        }, 3000);
      }
    };

    handleCallback();
  }, [navigate]);

  // Helper function to get readable error messages
  const getErrorMessage = (errorCode) => {
    const errorMessages = {
      'access_denied': 'Access was denied by the user.',
      'invalid_request': 'The authorization request was invalid.',
      'unauthorized_client': 'The application is not authorized to request access.',
      'unsupported_response_type': 'The authorization server does not support this response type.',
      'invalid_scope': 'The requested scope is invalid or malformed.',
      'server_error': 'The authorization server encountered an error.',
      'temporarily_unavailable': 'The authorization server is temporarily unavailable.',
    };

    return errorMessages[errorCode] || 'An unexpected error occurred during authentication.';
  };

  const StatusCard = ({ status, error }) => {
    const statusConfig = {
      processing: {
        icon: Loader2,
        title: 'Connecting to Google Drive',
        description: 'Please wait while we complete the connection...',
        iconClass: 'text-blue-500 animate-spin',
        bgClass: 'bg-blue-50'
      },
      success: {
        icon: CheckCircle,
        title: 'Connection Successful',
        description: 'Successfully connected to Google Drive.',
        iconClass: 'text-green-500',
        bgClass: 'bg-green-50'
      },
      error: {
        icon: XCircle,
        title: 'Connection Failed',
        description: error || 'Failed to connect to Google Drive.',
        iconClass: 'text-red-500',
        bgClass: 'bg-red-50'
      }
    };

    const config = statusConfig[status];
    const Icon = config.icon;

    return (
      <Card className={`w-full max-w-md ${config.bgClass} border-none`}>
        <CardContent className="pt-6">
          <div className="text-center space-y-4">
            <Icon className={`h-12 w-12 mx-auto ${config.iconClass}`} />
            <div className="space-y-2">
              <h2 className="text-xl font-semibold">{config.title}</h2>
              <p className="text-sm text-muted-foreground">{config.description}</p>
            </div>
            {status === 'error' && (
              <Button 
                variant="outline" 
                onClick={() => navigate('/settings')}
                className="mt-4"
              >
                Return to Settings
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    );
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-background p-4">
      <div className="w-full max-w-md">
        {/* Warning for users with JavaScript disabled */}
        <noscript>
          <div className="mb-4">
            <Card className="border-yellow-200 bg-yellow-50">
              <CardContent className="pt-6">
                <div className="flex items-center space-x-2 text-yellow-800">
                  <AlertCircle className="h-5 w-5" />
                  <p>JavaScript must be enabled to complete the authentication process.</p>
                </div>
              </CardContent>
            </Card>
          </div>
        </noscript>

        {/* Main Status Card */}
        <StatusCard status={status} error={error} />
      </div>
    </div>
  );
};

export default OAuthCallback;