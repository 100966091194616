// src/pages/Settings.jsx
import React from 'react';
import { useLocation } from 'react-router-dom';
import DashboardLayout from '../components/Layout/DashboardLayout';
import BusinessManagement from '../components/BusinessManagement';
import { useBusinesses } from '../hooks/useBusinesses';
import { useGoogleDrive } from '../hooks/useGoogleDrive';
import { Button } from '../components/ui/button';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '../components/ui/card';
import { Toast } from '../components/ui/toast';

const Settings = () => {
  const location = useLocation();
  const { businesses, selectBusiness } = useBusinesses();
  const { isAuthenticated, loading, startAuth } = useGoogleDrive();

  const handleBusinessAdded = () => {
    // Refresh the businesses list in the context
    if (selectBusiness) {
      selectBusiness();
    }
  };

  return (
    <DashboardLayout>
      <div className="space-y-4 sm:space-y-6">
        <div>
          <h1 className="text-2xl sm:text-3xl font-bold">Settings</h1>
          <p className="text-sm sm:text-base text-muted-foreground">
            Manage your businesses and account settings
          </p>
        </div>
  
        {/* Success/Error Messages */}
        {location.state?.message && (
          <Toast className="w-full sm:w-auto">
            <div className="bg-green-50 text-green-600 px-4 py-3 rounded text-sm sm:text-base">
              {location.state.message}
            </div>
          </Toast>
        )}
        {location.state?.error && (
          <Toast className="w-full sm:w-auto">
            <div className="bg-destructive/10 text-destructive px-4 py-3 rounded text-sm sm:text-base">
              {location.state.error}
            </div>
          </Toast>
        )}
  
        <div className="grid gap-4 sm:gap-6">
          {/* Google Drive Connection */}
          <Card>
            <CardHeader className="space-y-1">
              <CardTitle className="text-lg sm:text-xl">Google Drive Connection</CardTitle>
              <CardDescription className="text-sm">
                Connect to Google Drive to store your receipts
              </CardDescription>
            </CardHeader>
            <CardContent>
              {loading ? (
                <div className="text-sm text-muted-foreground">Checking connection status...</div>
              ) : isAuthenticated ? (
                <div className="flex items-center space-x-2">
                  <div className="h-2 w-2 bg-green-500 rounded-full"></div>
                  <span className="text-sm sm:text-base text-green-600">Connected to Google Drive</span>
                </div>
              ) : (
                <Button 
                  onClick={startAuth}
                  className="w-full sm:w-auto py-3 text-base"
                >
                  Connect Google Drive
                </Button>
              )}
            </CardContent>
          </Card>
  
          {/* Business Management */}
          <BusinessManagement onBusinessAdded={handleBusinessAdded} />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Settings;