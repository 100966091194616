// src/pages/Transactions.jsx
import React, { useState, useEffect } from 'react';
import { PlusCircle, Filter } from 'lucide-react';
import DashboardLayout from '../components/Layout/DashboardLayout';
import TransactionForm from '../components/TransactionForm';
import TransactionList from '../components/TransactionList';
import TransactionFilters from '../components/TransactionFilters';
import { useBusinesses } from '../hooks/useBusinesses';
import { useAuth } from '../hooks/useAuth';
import { transactionService } from '../services/transactionService';
import { Button } from '../components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '../components/ui/dialog';
import { Toast } from '../components/ui/toast';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../components/ui/alert-dialog";
import { Card } from '../components/ui/card';

const Transactions = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [transactionToDelete, setTransactionToDelete] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    type: '',
    category: '',
    paymentMethod: '',
    minAmount: '',
    maxAmount: ''
  });
  
  const { selectedBusiness } = useBusinesses();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (selectedBusiness && currentUser) {
      loadTransactions();
    }
  }, [selectedBusiness, currentUser, filters]);

  const loadTransactions = async () => {
    try {
      const data = await transactionService.getTransactions(
        selectedBusiness.id,
        currentUser.uid,
        filters
      );
      setTransactions(data);
    } catch (error) {
      console.error('Error loading transactions:', error);
      setError('Failed to load transactions');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (formData) => {
    try {
      await transactionService.createTransaction(
        {
          ...formData,
          businessId: selectedBusiness.id
        }, 
        currentUser.uid
      );
      setSuccessMessage('Transaction added successfully');
      setIsFormOpen(false);
      loadTransactions();
    } catch (error) {
      console.error('Error creating transaction:', error);
      setError('Failed to create transaction');
    }
  };

  const handleDeleteClick = (transactionId) => {
    setTransactionToDelete(transactionId);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await transactionService.deleteTransaction(transactionToDelete);
      setSuccessMessage('Transaction deleted successfully');
      loadTransactions();
    } catch (error) {
      console.error('Error deleting transaction:', error);
      setError('Failed to delete transaction');
    } finally {
      setIsDeleteDialogOpen(false);
      setTransactionToDelete(null);
    }
  };

  const handleFiltersApply = (newFilters) => {
    setFilters(newFilters);
    setLoading(true);
  };

  const handleFiltersReset = () => {
    setFilters({
      startDate: '',
      endDate: '',
      type: '',
      category: '',
      paymentMethod: '',
      minAmount: '',
      maxAmount: ''
    });
    setLoading(true);
  };

  return (
    <DashboardLayout>
      {/* Main Content */}
      <div className="space-y-4 sm:space-y-6 max-w-[1600px] mx-auto">
        {/* Header Section */}
        <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 sm:gap-6">
          <div>
            <h1 className="text-2xl sm:text-3xl font-bold">Transactions</h1>
            <p className="text-sm sm:text-base text-muted-foreground">
              Manage your expenses and income
            </p>
          </div>

          {/* Action Buttons */}
          <div className="flex items-center gap-2">
            <Button 
              variant="outline" 
              size="icon"
              className="h-10 w-10 sm:h-9 sm:w-9"
              onClick={() => setIsFiltersOpen(true)}
            >
              <Filter className="h-4 w-4" />
            </Button>
            <Button 
              onClick={() => setIsFormOpen(true)}
              className="flex-1 sm:flex-none h-10 sm:h-9"
            >
              <PlusCircle className="h-4 w-4 mr-2" />
              <span>Add Transaction</span>
            </Button>
          </div>
        </div>

        {/* Applied Filters Indicators (if any) */}
        {Object.values(filters).some(value => value !== '') && (
          <Card className="p-3 bg-muted/50">
            <div className="flex flex-wrap gap-2 text-sm">
              <span className="font-medium">Active filters:</span>
              {filters.startDate && <span>From: {filters.startDate}</span>}
              {filters.endDate && <span>To: {filters.endDate}</span>}
              {filters.type && <span>Type: {filters.type}</span>}
              {filters.category && <span>Category: {filters.category}</span>}
              {filters.paymentMethod && <span>Payment: {filters.paymentMethod}</span>}
              <Button 
                variant="link" 
                className="text-sm p-0 h-auto"
                onClick={handleFiltersReset}
              >
                Clear all
              </Button>
            </div>
          </Card>
        )}

        {/* Transactions List */}
        <div className="rounded-lg border bg-card">
          <TransactionList 
            transactions={transactions}
            loading={loading}
            onDelete={handleDeleteClick}
            onEdit={(transaction) => {
              // TODO: Implement edit functionality
              console.log('Edit transaction:', transaction);
            }}
          />
        </div>

        {/* Transaction Form Dialog */}
        <Dialog open={isFormOpen} onOpenChange={setIsFormOpen}>
          <DialogContent className="sm:max-w-2xl max-h-[90vh] overflow-y-auto">
            <DialogHeader className="sticky top-0 bg-background pt-6 pb-4">
              <DialogTitle className="text-xl">New Transaction</DialogTitle>
              <DialogDescription>
                Add a new expense or income transaction
              </DialogDescription>
            </DialogHeader>
            <TransactionForm 
              onSubmit={handleSubmit}
              onCancel={() => setIsFormOpen(false)}
            />
          </DialogContent>
        </Dialog>

        {/* Filters Dialog */}
        <TransactionFilters 
          isOpen={isFiltersOpen}
          onClose={() => setIsFiltersOpen(false)}
          filters={filters}
          onApplyFilters={handleFiltersApply}
          onResetFilters={handleFiltersReset}
        />

        {/* Delete Confirmation Dialog */}
        <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
          <AlertDialogContent className="max-w-md mx-4 sm:mx-auto">
            <AlertDialogHeader>
              <AlertDialogTitle>Delete Transaction</AlertDialogTitle>
              <AlertDialogDescription>
                Are you sure you want to delete this transaction? This action cannot be undone.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter className="sm:space-x-2">
              <AlertDialogCancel className="sm:mb-0">Cancel</AlertDialogCancel>
              <AlertDialogAction
                onClick={handleDeleteConfirm}
                className="bg-red-500 hover:bg-red-600"
              >
                Delete
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>

        {/* Toast Messages */}
        {successMessage && (
          <Toast 
            onOpenChange={() => setSuccessMessage('')}
            className="fixed bottom-4 right-4 sm:bottom-6 sm:right-6"
          >
            <div className="bg-green-50 text-green-600 px-4 py-3 rounded-lg shadow-lg">
              {successMessage}
            </div>
          </Toast>
        )}

        {error && (
          <Toast 
            onOpenChange={() => setError(null)}
            className="fixed bottom-4 right-4 sm:bottom-6 sm:right-6"
          >
            <div className="bg-destructive/10 text-destructive px-4 py-3 rounded-lg shadow-lg">
              {error}
            </div>
          </Toast>
        )}
      </div>
    </DashboardLayout>
  );
};

export default Transactions;