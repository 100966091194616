// src/components/BusinessSelector.jsx
import React from 'react';
import { useBusinesses } from '../hooks/useBusinesses';
import { Building2 } from 'lucide-react';
import { 
  Card, 
  CardHeader, 
  CardTitle, 
  CardDescription, 
  CardContent 
} from './ui/card';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select';

const BusinessSelector = () => {
  const { businesses, selectedBusiness, selectBusiness, loading } = useBusinesses();

  if (loading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <Building2 className="h-5 w-5" />
            Loading...
          </CardTitle>
        </CardHeader>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Building2 className="h-5 w-5" />
          Select Business
        </CardTitle>
        <CardDescription>Choose which business you want to manage</CardDescription>
      </CardHeader>
      <CardContent>
        <Select
          value={selectedBusiness?.id}
          onValueChange={selectBusiness}
        >
          <SelectTrigger className="w-full md:w-1/2">
            <SelectValue placeholder="Select a business" />
          </SelectTrigger>
          <SelectContent>
            {businesses.map((business) => (
              <SelectItem key={business.id} value={business.id}>
                {business.name} ({business.type})
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </CardContent>
    </Card>
  );
};

export default BusinessSelector;